<template>
    <div class="wrapper-contacto column-center">

        <div class="contenedor-fondo">
            <div class="superior"></div>
            <div class="inferior"></div>
        </div>


        <div class="wrapper-contenedor-formulario column-center">

            <h2>Contacto</h2>

            <div class="contenedor-main-formulario row-center ais">

                <div class="contenedor-formulario">

                    <h3>Mándanos un mensaje</h3>


                    <div class="row-center aife form_contact" id="formContacto">

                        <ValidationObserver ref="form" v-slot="{failed,valid}">
                            <form class="" @submit.prevent="validate" :valid="valid" autocomplete="off">

                                <div class="js-contacto contenedor-campos row-between fww">
                                    <div class="contenedor-input">
                                        <ValidationProvider rules="required"
                                            v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                            <input autocomplete="off" type="text" name="nombre"
                                                placeholder="Nombre y apellidos" v-model="data.nombre" :class="classes">
                                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>


                                    <div class="contenedor-input half">
                                        <ValidationProvider rules="required"
                                            v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                            <input autocomplete="off" type="text" name="movil" placeholder="Móvil"
                                                v-model="data.telefono" :class="classes">
                                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="contenedor-input half">
                                        <ValidationProvider rules="required"
                                            v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                            <input autocomplete="off" type="email" name="mail" placeholder="Email"
                                                v-model="data.email" :class="classes">
                                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>

                                    <div class="contenedor-input">
                                        <textarea name="mensaje" v-model="data.mensaje" required
                                            placeholder="Escribe aquí tu mensaje" id="" maxlength="1000"></textarea>
                                    </div>

                                    <div class="text_politica">
                                        <p>El responsable del tratamiento de sus datos es la Universidad Francisco de
                                            Vitoria
                                            (UFV).</p>
                                        <p>La finalidad del tratamiento es</p>
                                        <div style="padding: 0 15px;">
                                            <ul>
                                                <li>Enviarle, por cualquier medio, incluidos los electrónicos (SMS,
                                                    Whatsapp,
                                                    correo electrónico y redes sociales), comunicaciones relacionadas
                                                    con
                                                    empleabilidad, observatorio de empleo, ofertas, promociones,
                                                    productos,
                                                    actividades, concursos y servicios de la Universidad Francisco de
                                                    Vitoria
                                                    y/o patrocinados por la misma.</li>
                                                <li>Gestionar su inscripción en las actividades organizadas por la
                                                    Universidad
                                                    Francisco de Vitoria (UFV).</li>
                                                <li>Publicar videos e imágenes en las diferentes páginas webs de la
                                                    universidad
                                                    y redes sociales en las que pueda aparecer a raíz de su
                                                    participación en las
                                                    diferentes actividades de la universidad (conferencias, concursos,
                                                    etc.). En
                                                    el caso de que se le invitara a participar en actuaciones
                                                    específicas de
                                                    promoción de la Universidad Francisco de Vitoria, se le pediría su
                                                    consentimiento documentalmente si su imagen tuviera un carácter
                                                    relevante.
                                                </li>
                                            </ul>

                                        </div>

                                        <p>
                                            La legitimación del tratamiento se basa en el consentimiento del interesado
                                            marcando
                                            la casilla destinada a tal efecto, y el interés legítimo del responsable,
                                            según se
                                            detalla en la información ampliada. Los datos se mantendrán mientras usted
                                            no
                                            revoque el consentimiento otorgado o no se oponga al tratamiento realizado a
                                            través
                                            del link:<a href="https://www.alumni-ufv.es/es/general/solicitar-baja"
                                                target="_blank">https://www.alumni-ufv.es/es/general/solicitar-baja</a>
                                        </p>
                                        <p>La información facilitada no será comunicada a terceros ajenos a la UFV,
                                            salvo a
                                            prestadores de servicio con acceso a datos. </p>
                                        <p>Puede ejercitar sus derechos de acceso, rectificación, supresión, oposición,
                                            limitación del tratamiento y portabilidad mediante un escrito dirigido a la
                                            Secretaría General de la Universidad Francisco de Vitoria, Ctra. M-515
                                            Pozuelo-Majadahonda Km. 1,800; 28223, Pozuelo de Alarcón (Madrid), o al
                                            correo
                                            electrónico <a href="mailto:dpd@ufv.es.">dpd@ufv.es.</a> </p>
                                        <p>
                                            Si a través del presente formulario facilita datos de terceras personas, le
                                            informamos de que queda obligado a informar al interesado sobre el contenido
                                            de esta
                                            cláusula.</p>

                                        <p>
                                            Puede consultar la información ampliada en la web <a
                                                href="https://www.alumni-ufv.es/es/general/politica-privacidad"
                                                target="_blank">https://www.alumni-ufv.es/es/general/politica-privacidad</a>
                                        </p>


                                    </div>


                                    <div class="contenedor-input">
                                        <ValidationProvider rules="required"
                                            v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                            <input type="checkbox" id="contact" 
                                                :class="classes" name="contact" v-model="data.privacy_check">
                                            <label for="contact">He leído y acepto la <a target="_blank"
                                                    href="/es/general/politica-privacidad">Política de
                                                    privacidad</a></label>
                                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>

                                </div>


                                <div class="contenedor-submit">

                                    <button :loading="loading" type="submit"
                                        class="boton-azul js-send-form">Enviar</button>

                                </div>



                            </form>

                        </ValidationObserver>



                    </div>


                </div>

                <div class="info-contacto">
                    <h3>Información de contacto</h3>
                    <p class="telefono"><a href="tel:917091400">91 709 14 00</a></p>
                    <p class="whatsapp"><a href="https://api.whatsapp.com/send?phone=660250033">660 250 033</a></p>
                    <p class="mail"><a href="mailto:familias@ufv.es">familias@ufv.es</a></p>
                    <p class="localizacion">Módulo 4 edificio central</p>
                    <h4>Tambíen puedes seguirnos en</h4>
                    <ul class="social row-center">
                       
                        <li><a target="_blank" href="https://www.instagram.com/ufvfamilias/"><i
                                    class="fa fa-instagram"></i></a></li>
                        <li><a target="_blank" href="https://educamedia.ufv.es/category/Vida+universitaria%3EFamilias/262079442"><i class="fa fa-youtube"></i></a></li>
                        <li><a target="_blank" href="https://open.spotify.com/show/0lZY95pi1dh152jPtewiF1?si=voqRPlkpRtmb3_Vr0g5fQw"><i class="fa fa-spotify"></i></a></li>
                    </ul>

                </div>

            </div>

        </div>

    </div>
</template>


<script>

    import { mapActions } from 'vuex';

    export default {
        name: 'contacto',
        data: () => ({
            data: {
                nombre: '',
                telefono: '',
                email: '',
                mensaje: '',
                privacy_check: false
            },
            loading: false
        }),
        methods: {
            ...mapActions(['sendContact']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                    

                        this.sendContact(this.data).then(r => {
                            this.loading = false;
                            if (r) {
                                this.data = {
                                    nombre: '',
                                    telefono: '',
                                    email: '',
                                    mensaje: '',
                                    privacy_check: false
                                }

                                this.$refs.form.reset();
                            }
                        })

                    }
                });
            },
        }
    }
</script>